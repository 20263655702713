import React, { ReactNode } from "react";
import { Row, Col, Card, Empty, Tooltip, Space } from "antd";
import i18n from "i18next";

import Text from "common/components/general/Text";
import Image from "common/components/general/Image";
import Icon from "common/components/general/Icon";
import ClassPendingImg from "assets/images/class-pending.svg";
import { EnrollmentStatus } from "services/domain/administration/EnrollmentStatus";
import { TypeOfClassroomBasedOnEnrollments } from "services/domain/administration/Classroom";
import { ArchivedStatusAvatar } from "./ArchivedStatusAvatar";
import { turnNumberToRange } from "common/utils/NumberUtils";
// import { isYieldExpression } from "typescript";

export interface ClassItem {
	id: string;
	showAsTeacher: boolean;
	showAsStudent: boolean;
	name?: string;
	teacherName?: string;
	lecturerName?: string;
	filePath?: string;
	isLive: boolean;
	isAsync?: boolean;
	pathToClassroom: string;
	status: EnrollmentStatus;
	requestDate?: string;
	organizationName?: string;
	classroomStatus: string;
	totalEnrollments?: number;
	virtualClassroomType?: TypeOfClassroomBasedOnEnrollments;
}

interface ClassSliderProps {
	title: string;
	items: ClassItem[];
	defaultImg: string;
	noDataDesc?: string;
	emptyState?: ReactNode;
}

const TRANSLATION_BASE_PATH = "_MAIN._CLASSES";
const showEnrolledUsers = false;

class ClassSlider extends React.Component<ClassSliderProps, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			index: 0,
			showAll: false,
			showAllVisible: this.props.items.length > this.decrementFromWindowSize,
			sliderLeftVisible: false,
			sliderRightVisible: this.props.items.length > this.decrementFromWindowSize,
			items: this.props.items,
			currentItems: this.props.items.slice(0, this.decrementFromWindowSize)
		};
	}

	setInitialState() {
		this.setState({
			index: 0,
			showAllVisible: this.props.items.length > this.decrementFromWindowSize,
			sliderLeftVisible: false,
			sliderRightVisible: !this.state.showAll && this.props.items.length > this.decrementFromWindowSize,
			items: this.props.items,
			currentItems: this.props.items
		});
	}

	componentDidMount() {
		window.addEventListener("resize", this.setInitialState.bind(this));
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.setInitialState.bind(this));
	}

	toggleShowAll = () => {
		this.setState({
			index: 0,
			showAll: !this.state.showAll,
			sliderLeftVisible: false,
			sliderRightVisible: this.state.showAll,
			currentItems: this.props.items
		});
	};

	scroll = (step: number) => {
		if (this.state.index + step >= 0) {
			this.setState({
				index: this.state.index + step,
				sliderLeftVisible: this.state.index + step > 0,
				sliderRightVisible: this.state.index + step < this.state.items.length - this.decrementFromWindowSize,
				currentItems: this.state.items.slice(
					this.state.index + step,
					this.state.index + this.decrementFromWindowSize + 1
				)
			});
		}
	};

	get decrementFromWindowSize(): number {
		let decrement = 1;
		if (window.innerWidth >= 992) {
			decrement = 4;
		} else if (window.innerWidth >= 768) {
			decrement = 3;
		} else if (window.innerWidth >= 576) {
			decrement = 2;
		}
		return decrement;
	}

	get Title(): ReactNode {
		return (
			<Col span="12">
				<Row justify="start">
					<Text fontSize="16" lineHeight="24" wheight="semibold" className="color-gray-9">
						{this.props.title}
					</Text>
				</Row>
			</Col>
		);
	}


	get ShowAllLink(): ReactNode {
		const allLabel = i18n.t(`${TRANSLATION_BASE_PATH}._LINK_SHOW_ALL`);
		const someLabel = i18n.t(`${TRANSLATION_BASE_PATH}._LINK_NOT_SHOW_ALL`);
		const label = this.state.showAll ? someLabel : allLabel;
		return (
			this.state.showAllVisible && (
				<Col span="12">
					<Row justify="end">
						<Text
							fontSize="14"
							lineHeight="22"
							className="color-blue-6 cursor_pointer"
							onClick={this.toggleShowAll}
						>
							{label}
						</Text>
					</Row>
				</Col>
			)
		);
	}


	get LeftScrollButton() {
		const iconClass = "main__classSlider__nav__icon main__classSlider__nav__icon__left";

		return (
			this.state.sliderLeftVisible && (
				<a className={iconClass} onClick={() => this.scroll(-1)}>
					<Icon type="ri-arrow-left-s-line" />
				</a>
			)
		);
	}

	get RightScrollButton() {
		const iconClass = "main__classSlider__nav__icon main__classSlider__nav__icon__right";

		return (
			this.state.sliderRightVisible && (
				<a className={iconClass} onClick={() => this.scroll(+1)}>
					<Icon type="ri-arrow-right-s-line" />
				</a>
			)
		);
	}

	getPhoto(item: ClassItem) {
		return (
			<Image
				className="main__classSlider__item__card__image"
				src={item.status === "PENDING" ? ClassPendingImg : item.filePath ?? this.props.defaultImg}
			/>
		);
	}

	getItem(item: ClassItem): ReactNode {
		return (

			<a href={item.pathToClassroom}>


				<Tooltip title={item.status === "PENDING" ? "Dërguar " + item.requestDate : ""} placement="top">
					<Row className="mb-16">

						{this.getPhoto(item)}
						{item.classroomStatus === "ARCHIVED" && (
							<ArchivedStatusAvatar />
						)}
					</Row>
				</Tooltip>



				{!item.isAsync && !item.showAsTeacher && (
					<Row>
						<Col span={24}>
							<Space direction="horizontal" size={4} align="center">
								<Icon type="ri-user-2-line" className="color-gray-7" fontSize="14" />
								<Text
									maxLength={item.teacherName && item.teacherName.length > 15 ? 15 : undefined}
									fontSize="14"
									lineHeight="22"
									className="color-gray-7"
								>
									{item.teacherName}
								</Text>
							</Space>
						</Col>
					</Row>
				)}
				{item.isAsync && !item.showAsTeacher && (
					<Row>
						<Col span={24}>
							<Text
								fontSize="12"
								lineHeight="20"
								className="pl-8 pr-8 color-gray-7 background-color-gray-3"
							>
								{item.organizationName}
							</Text>
						</Col>
					</Row>
				)}
				<Row>
					<Text
						maxLength={item.name !== undefined && item.name?.length > 25 ? 25 : undefined}
						fontSize="16"
						lineHeight="24"
						className="color-gray-9"
					>
						{item.name}
					</Text>
				</Row>
				{showEnrolledUsers && (
					<Row className="mt-8">
						<Col span={24}>
							<Space direction="horizontal" size={4} align="center">
								<Icon type="ri-user-line" className="color-gray-8" fontSize="14" />
								<Text fontSize="14" lineHeight="22" className="color-gray-8">
									{item.totalEnrollments &&
										item.isAsync &&
										item.totalEnrollments < 500
										? turnNumberToRange(item.totalEnrollments, 500, 1000)
										: item.totalEnrollments
									}
								</Text>
							</Space>
						</Col>
					</Row>
				)}
			</a>
		);
	}

	get Items(): ReactNode {
		return (
			this.state.currentItems &&
			this.state.currentItems.length > 0 &&
			this.state.currentItems.map((item: ClassItem) => {
				return (
					<Col key={item.id} xs={24} sm={12} md={8} lg={6} xl={6} xxl={6} className="pr-16 pb-24">
						{this.getItem(item)}
					</Col>
				);
			})
		);
	}

	get EmptyState(): ReactNode {
		return (
			<Col span={24}>
				{this.props.emptyState ?? <Empty className="pb-16" description={this.props.noDataDesc} />}
			</Col>
		);
	}

	render() {
		const showSomeAllClass = this.state.showAll
			? "main__classSlider__card__show__all"
			: "main__classSlider__card__show__some";
		const cardClass = "main__classSlider__card " + showSomeAllClass;
		const Data = this.state.currentItems && this.state.currentItems.length > 0;
		const justifyItems = !Data ? "center" : "start";
		return (
			<Row justify="center">
				<Card className={cardClass}>
					<Row className="pb-16 pr-16">
						{this.Title}
						{Data && this.ShowAllLink}
					</Row>
					<Row justify={justifyItems} align="middle">
						{this.LeftScrollButton}
						{this.Items}
						{this.RightScrollButton}
						{!Data && this.EmptyState}
					</Row>
				</Card>
			</Row>
		);
	}
}

export default ClassSlider;

import React, { ReactNode, useState } from "react";
import { Layout, Space, Row, Col, Menu, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import { useStoreState } from "store/hooks";
import { orderBy } from "natural-orderby";

import Logo from "assets/images/icons/Logo.svg";
import Button from "common/components/general/Button";
import Icon from "common/components/general/Icon";
import Image from "common/components/general/Image";
import Text from "common/components/general/Text";
import LoggedInMenu from "common/components/_loggedInMenu/LoggedInMenu";
import { SUBJECTS_PATH } from "scenes/subjects";
import { DASHBOARD_PATH, MAIN_PATH } from "scenes/main";
import { COURSE_PREVIEW_PATH } from "scenes/subjects/scenes/subjectItems/scenes/courseItem/CourseItem";
import TabPane from "../../dataDisplay/TabPane";
import { MainMenu } from "../../_leftSideBar/components/MainMenu";
import Notifications from "../../_notifications/Notifications";
import { OrganizationInfo } from "./OrganizationInfo";
import OAuth2Service from "services/authentication/oauth2.service";
import { getDesignFile } from "../../../utils/Design";

import { SIGN_IN_PATH } from "scenes/login/scenes/signIn";
import { REGISTER_PATH } from "scenes/login/scenes/register";

import { loggedOutMenuLinks } from "./loggedOutMenuLinks";

const { Header } = Layout;
const { SubMenu } = Menu;

const getSubMenuTitleContent = (icon: string, name: string, externalUrl?: string): ReactNode => {
	return (
		<span onClick={() => externalUrl && window.open(externalUrl)}>
			<Space>
				<Icon type={`ri-${icon}`} />
				<Text fontSize="14" lineHeight="22" className="color-gray-8">
					{name}
				</Text>
			</Space>
		</span>
	);
};

interface MainHeaderProps {
	showMarketplace?: boolean;
	menu?: any;
	noDashboardButton?: any;
	showSwitchRole?: any;
	showAllCoursesOnMarketPlace?: () => void
}

export const MainHeader = (props: MainHeaderProps) => {
	const { t: translate } = useTranslation();
	const items = useStoreState(state => state.subjects.menuItems);
	const { pathname } = useLocation();
	const history = useHistory();
	const [fullScreenMenuPage, toggleFullScreenMenu] = useState<ReactNode>();
	const [currentTab, changeTab] = useState(props.menu ? "menu" : "subjects");
	const primaryLogo = useStoreState(state => state.designSettings.items.primaryLogo);
	const designLoading = useStoreState(state => state.designSettings.loading);

	const menu = (modeInline?: any) => {
		const itemsFilterWithClassroom = items.filter(
			item => (item.classrooms && item.classrooms.length > 0) || item.externalUrl
		);
		const subjectOrdered =
			(itemsFilterWithClassroom && orderBy(itemsFilterWithClassroom, [v => v.name], ["asc"])) || [];
		return (
			<Menu mode={modeInline ? "inline" : "vertical"}>
				{subjectOrdered.map((subject, index) => {
					if (subject.classrooms.length > 0 || subject.externalUrl) {
						const orderClassroom =
							(subject && orderBy(subject.classrooms, [(v: any) => v.name], ["asc"])) || [];
						return (
							<SubMenu
								title={getSubMenuTitleContent(subject.icon, subject.name, subject.externalUrl)}
								key={index}
							>
								{orderClassroom.map((classroom, cIndex) => {
									return (
										<Menu.Item
											key={cIndex}
											onClick={() => {
												toggleFullScreenMenu(null);
												history.push(
													`/${SUBJECTS_PATH}/${COURSE_PREVIEW_PATH}/${classroom.id}`
												);
											}}
										>
											<Space>{classroom.name}</Space>
										</Menu.Item>
									);
								})}
							</SubMenu>
						);
					}

					return (
						<Menu.Item
							key={subject.id}
							onClick={() => {
								toggleFullScreenMenu(null);
								history.push(`/${SUBJECTS_PATH}/${subject.id}`);
							}}
						>
							{getSubMenuTitleContent(subject.icon, subject.name, subject.externalUrl)}
						</Menu.Item>
					);
				})}
			</Menu>
		);
	};

	return (
		<>
			<Header className="header__marketplace_container">
				<Row justify="space-between" align="middle">
					{!OAuth2Service.isOwnerOrAdminAtDefaultOrganization && (
						<Col xs={0} sm={8} md={9} lg={8} xl={10} xxl={10}>
							<Row align="middle">
								<Col>
									{pathname === "/shiko-mplc" ? (
										<button
											className="header__marketplace_button"
											style={{ border: "none" }}
											onClick={props.showAllCoursesOnMarketPlace}
										>
											<Text className="header__marketplace_button_text" fontSize="14">
												{translate("_MAIN._FULLSCREEN._MARKETPLACE_BTN")}
											</Text>
											<Icon type="ri-arrow-right-line" />
										</button>
									) : (
										<Link to={`/${SUBJECTS_PATH}`} className="header__marketplace_button">
											<Text className="header__marketplace_button_text" fontSize="14">
												{translate("_MAIN._FULLSCREEN._MARKETPLACE_BTN")}
											</Text>
											<Icon type="ri-arrow-right-line" />
										</Link>
									)}
								</Col>
							</Row>
						</Col>
					)}
					<Col xs={fullScreenMenuPage ? 0 : 2} sm={0} md={0} lg={0} xl={0} xxl={0}>
						<Row align="middle">
							<Col>
								<Icon
									type="ri-menu-2-line"
									onClick={() => {
										toggleFullScreenMenu(
											OAuth2Service.CurrentUser ? (
												<Tabs defaultActiveKey={currentTab} onChange={changeTab} centered>
													<TabPane tab={translate("_MAIN._FULLSCREEN._TAB_MENU")} key="menu">
														<MainMenu
															toggle={() => toggleFullScreenMenu(null)}
															menu={props.menu}
														/>
														<OrganizationInfo hideMenu={() => toggleFullScreenMenu(null)} />
													</TabPane>
													<TabPane
														tab={translate("_MAIN._FULLSCREEN._TAB_SUBJECTS")}
														key="subjects"
													>
														{menu(true)}
													</TabPane>
													<TabPane
														tab={
															<a href="/subjects" className="ml-24">
																<Space>
																	<Icon type="ri-store-line"></Icon>
																	{translate("_MAIN._FULLSCREEN._TAB_MARKETPLACE")}
																</Space>
															</a>
														}
														key={"marketplace"}
													></TabPane>
												</Tabs>
											) : (
												<MainMenu
													toggle={() => toggleFullScreenMenu(null)}
													menu={loggedOutMenuLinks}
												/>
											)
										);
									}}
								/>
							</Col>
						</Row>
					</Col>
					<Col xs={fullScreenMenuPage ? 22 : 14} sm={6} md={5} lg={6} xl={6} xxl={6}>

						<Row justify={"start"}>
							<Col>
								<a
									className="header__logo"
									onClick={() => {
										toggleFullScreenMenu(null);
										history.push(`/${MAIN_PATH}/${DASHBOARD_PATH}`);
									}}
								>
									{!designLoading && <Image src={getDesignFile(Logo, primaryLogo)} />}
								</a>
							</Col>
						</Row>

					</Col>
					{!fullScreenMenuPage && (
						<Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8}>
							<Row justify="end">
								<Space direction="horizontal" size={24}>
									{OAuth2Service.CurrentUser ? (
										<>
											<Col xs={0} sm={0} md={0} lg={24} xl={24} xxl={24}>

												{!props.noDashboardButton && (
													<Button
														htmlType="button"
														type="primary"
														ghost
														className="color-blue-6"
														onClick={() => history.push(`/${MAIN_PATH}/${DASHBOARD_PATH}`)}
													>
														{translate("_MAIN._MY_DASHBOARD")}
													</Button>
												)}
											</Col>
											<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
												<Notifications history={history} />
											</Col>
											<Col>
												<LoggedInMenu showSwitchRole={props.showSwitchRole} />
											</Col>
										</>
									) : (
										<>
											<Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
												<a
													href="https://akademial.tawk.help/"
													style={{ padding: "0.3em 1em", fontSize: "1rem", fontWeight: 400 }}
												>
													<Icon type="ri-question-line mr-4" />
													{translate("_MAIN._MENU_HELP")}
												</a>
											</Col>
											<Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
												<StyledLink to={`/${SIGN_IN_PATH}`}>
													{translate("_LOGIN._SIGNIN._SIGNIN_LABEL")}
												</StyledLink>
											</Col>
											<Col xs={0} sm={24} md={24} lg={24} xl={24} xxl={24}>
												<StyledLink to={`/${SIGN_IN_PATH}/${REGISTER_PATH}`} filled>
													{translate("_LOGIN._REGISTER._REGISTER_LABEL")}
												</StyledLink>
											</Col>
										</>
									)}
								</Space>
							</Row>
						</Col>
					)}
					{fullScreenMenuPage && (
						<Col xs={2}>
							<Row justify="end">
								<Text lineHeight="24" fontSize="24">
									<Icon onClick={() => toggleFullScreenMenu(null)} type="ri-close-line"></Icon>
								</Text>
							</Row>
						</Col>
					)}
				</Row>
			</Header>
			{fullScreenMenuPage && (
				<Row className="fullScreenMenu">
					<Col span={24}>{fullScreenMenuPage}</Col>
				</Row>
			)}
		</>
	);
};

interface StyledLinkProps {
	filled?: boolean
}

const StyledLink = styled(Link) <StyledLinkProps>`
	padding: 0.3em 1em;
    border: 1px solid #2063E3;
    border-radius: 6px;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.7em;
	color: ${({ filled }) => filled ? "#ffffff" : "#2063E3"};
	background-color: ${({ filled }) => filled ? "#2063E3" : "transparent"};
	background-size: cover;
	background-position: 50%;
	background-repeat: no-repeat;
	transition: all .2s;

	&:hover {
		color: ${({ filled }) => filled ? "#ffffff" : "#2063E3"};
		background-color: ${({ filled }) => !filled && "rgba(0, 0, 0, .05)"};
	}

	@media (max-width: 800px) {
		padding: 0.3em .8em;
		font-weight: 400;
	}
`;
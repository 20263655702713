import { Col, Empty, Menu, Modal, Row, Skeleton } from "antd";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { SectionTitle } from "../../../../components/sectionTitle/SectionTitle";
import { SectionContent } from "../../../../components/sectionContent/SectionContent";
import { DASHBOARD_PATH } from "../../../dashboard";
import { ORGANIZATIONS_PATH } from "../list";
import CounterCard from "common/components/_counterCard/CounterCard";
import { ResponsiveSearchBox } from "../../../../components/searchBox/ResponsiveSearchBox";
import { SearchUtil } from "../../../../util/SearchData";
import { AdminTable } from "../../../../components/table/table";
import { Space } from "common/components/layout/Space";
import { CircularIcon } from "common/components/general/CircularIcon";
import Icon from "common/components/general/Icon";
import { formatTotal } from "../../../../util/formatTotal";
import { ADMIN_PATH } from "../../../../";
import { showError, showMessage } from "common/utils/Notification";
import OrganizationService from "services/administration/organization.service";
import OrganizationDto from "dtos/administration/organization.dto";
import AnalyticsOrganizationService from "services/analytics/organization.service";
import { TEACHER_PATH } from "../teacher";
import { TableRowNavLink } from "../../../../components/tableRowNavLink/TableRowNavLink";
import { sumTotal } from "../../../../util/sumTotal";
import { EditDrawer } from "../../components/editDrawer/EditDrawer";
import { OrganizationUpdateAttribute } from "services/domain/administration/Organization";
import InvitationService from "services/administration/invitation.service";
import { ModalInfoDNS } from "../../../../components/modalInfoDNS/ModalInfoDNS";
import {
	getEnvironmentFromDomain,
	getSubdomain,
	isRequestCertificateRequired,
	isReservedDomain
} from "services/common/check-domain";
import ACMService from "services/s3/acm.service";
import ELBV2Service from "services/s3/elbv2.service";
import { loadCertificationDomainState } from "../../utils/utils";
import { OrganizationSectors } from "../../utils/constants";

export const ORGANIZATION_PATH = "organization";

const TRANSLATION_BASE_PATH = "_ADMIN._ORGANIZATIONS._ORGANIZATION";

class OrganizationComponent extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			loading: false,
			errorMessage: "",
			drawerShown: false,
			organizationDto: {},
			cardTotalTeachersByOrganization: null,
			cardTotalVirtualClassesByOrganization: null,
			cardTotalStudentsByOrganization: null,
			cardTotalStudentsByOrganizationLoading: false,
			data: [],
			filteredData: [],
			editDomainModalFormVisible: false,
			editOwnerEmailModalFormVisible: false,
			showModalInfoDNS: false,
			certificateCreated: null
		};
	}

	componentDidMount() {
		this.setState(
			{
				organizationId: this.props.match.params.id
			},
			() => {
				this.loadOrganizationProfile();
				this.getTotalStudents();
				return this.loadTable().then(() => {
					return this.calcCardsTotals();
				});
			}
		);
	}

	getTotalStudents() {
		this.setState({
			cardTotalStudentsByOrganizationLoading: true
		});

		return new AnalyticsOrganizationService()
			.countOrganizationTotalStudents(this.state.organizationId)
			.then((result: any) => {
				return this.setState({
					cardTotalStudentsByOrganization: result
				});
			})
			.finally(() => {
				this.setState({
					cardTotalStudentsByOrganizationLoading: false
				});
			});
	}

	calcCardsTotals() {
		this.setState({
			cardTotalTeachersByOrganization: this.state.data.length,
			cardTotalVirtualClassesByOrganization: sumTotal(this.state.data, "classCount")
		});
	}

	loadTable = () => {
		this.setState({
			loading: true
		});

		return new AnalyticsOrganizationService()
			.getOrganizationTotals(this.state.organizationId)
			.then((result: any) => {
				return this.setState({
					data: result,
					filteredData: result
				});
			})
			.finally(() => {
				this.setState({
					loading: false
				});
			});
	};

	/**
	 * Load organization data
	 */
	loadOrganizationProfile = () => {
		return new OrganizationService().findOrganizationById(this.state.organizationId).then((organization: any) => {
			return this.setState(
				{
					organizationDto: organization
				},
				() => this.loadCertificationDomainState()
			);
		});
	};

	/**
	 * Get domain certificate
	 */
	async loadCertificationDomainState() {
		const res = await loadCertificationDomainState(this.state.organizationDto.domain);
		return this.setState({
			organizationDto: {
				...this.state.organizationDto,
				certificationState: res.certificationState,
				certificationLinked: res.certificationLinkedState
			}
		});
	}

	search = (value: string) => {
		this.setState({
			filteredData: SearchUtil(this.state.data, value, { column1: "firstName", column2: "lastName" })
		});
	};

	get columnsTable() {
		const { t: translate } = this.props;

		return [{
			title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._TEACHER_NAME`),
			dataIndex: "name",
			key: "name",
			render: (text: any, row: any) => {
				return (
					<Row align="middle">
						<Space>
							<Col xs={0} sm={4} md={4} lg={4} xl={3} xxl={2}>
								<CircularIcon type="ri-user-2-line" />
							</Col>
							<Col>
								<Row>
									{row.firstName} {row.lastName}
								</Row>
							</Col>
						</Space>
					</Row>
				);
			}
		},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._VIRTUAL_CLASSES`),
				dataIndex: "classCount",
				key: "classCount",
				defaultSortOrder: "descend",
				sorter: (a: any, b: any) => a.classCount - b.classCount,
				responsive: ["sm"],
				align: "right",
				render: (text: any) => {
					return formatTotal(text);
				}
			},
			{
				title: translate(`${TRANSLATION_BASE_PATH}._TABLE._COLUMNS._STUDENTS`),
				dataIndex: "studentCount",
				key: "studentCount",
				defaultSortOrder: "descend",
				sorter: (a: any, b: any) => a.studentCount - b.studentCount,
				responsive: ["md"],
				align: "right",
				render: (text: any) => {
					return formatTotal(text);
				}
			},
			{
				title: "",
				dataIndex: "id",
				key: "id",
				align: "center",
				render: (id: any, row: any) => {
					const to = `/${ADMIN_PATH}/${ORGANIZATIONS_PATH}/${this.state.organizationDto.id}/${this.state.organizationDto.name}
					/${TEACHER_PATH}/${id}/${row.firstName} ${row.lastName}`;
					return <TableRowNavLink to={to} />;
				}
			}];
	}

	loadItemAndShowDrawer = (item: OrganizationDto) => {
		this.setState(
			{
				formInitialValues: {
					id: item.id,
					name: item.name,
					code: item.code,
					nationality: item.nationality,
					type: item.type,
					contentType: item.contentType,
					sector: item.sector,
					domain: item.domain,
					numberOfUsers: item.numberOfUsers,
					price: item.price,
					ratio: item.ratio,
					ownerEmail: item.ownerEmail,
					status: item.status,
					about: item.about,
					subtitle: item.subtitle,
					facebook: item.facebook,
					twitter: item.twitter,
					instagram: item.instagram,
					featured: item.featured,
					featuredSort: item.featuredSort || 1,
					marketplaceLogo: item.marketplaceLogo
				}
			},
			() => {
				this.toggleDrawer();
			}
		);
	};

	onEdit = () => {
		const item = this.state.organizationDto;
		this.loadItemAndShowDrawer(item);
	};

	openDeleteConfirmationModal = () => {
		const { t: translate, history } = this.props;

		return Modal.confirm({
			title: translate(`${TRANSLATION_BASE_PATH}._DELETE_TITLE`),
			content: translate(`${TRANSLATION_BASE_PATH}._DELETE_MESSAGE`, {
				orgName: this.state.organizationDto?.name
			}),
			okText: translate(`${TRANSLATION_BASE_PATH}._YES_DELETE`),
			cancelText: translate(`${TRANSLATION_BASE_PATH}._CANCEL`),
			onOk: () =>
				this.onDelete()
					.then(() => history.push(`/${ADMIN_PATH}/${ORGANIZATIONS_PATH}`))
					.catch(() => {
						return;
					})
		});
	};

	onDelete = async () => {
		const { t: translate } = this.props;
		return new OrganizationService()
			.findOrganizationById(this.state.organizationId)
			.then((organization: any) => {
				if (organization.status === "ACTIVE")
					throw showError(
						translate(`${TRANSLATION_BASE_PATH}._ERROR_ORGANIZATION_NO_DELETE`, {
							orgName: organization.name
						})
					);
				return this.checkOldDomainToDelete(this.state.organizationDto?.domain);
			})
			.then(() => {
				return new OrganizationService().deleteOrganization(this.state.organizationId);
			})
			.then(() => {
				return showMessage(
					translate(`${TRANSLATION_BASE_PATH}._DELETE_SUCCESS`, { orgName: this.state.organizationDto?.name })
				);
			});
	};

	onConnectCertification = () => {
		const { t: translate } = this.props;
		const generateRequestCertificate: any =
			this.state.organizationDto && isRequestCertificateRequired(this.state.organizationDto?.domain);
		if (generateRequestCertificate)
			return new ACMService()
				.getCertification(this.state.organizationDto?.domain)
				.then((certificationArn: string) => {
					if (!certificationArn)
						throw this.awsDomainError(
							translate(`${TRANSLATION_BASE_PATH}._ERROR_DOMAIN_NOT_FOUND`, {
								domain: this.state.organizationDto?.domain
							})
						);
					return new ELBV2Service().addCertificateToListener(
						certificationArn,
						getEnvironmentFromDomain(window.location.host)
					);
				});
		throw this.awsDomainError(
			translate(`${TRANSLATION_BASE_PATH}._ERROR_NOT_ALLOWED_TO_SEE_CERT`, {
				domain: this.state.organizationDto?.domain
			})
		);
	};

	onViewCertification = () => {
		const { t: translate } = this.props;
		const generateRequestCertificate: any =
			this.state.organizationDto && isRequestCertificateRequired(this.state.organizationDto?.domain);
		if (generateRequestCertificate)
			return new ACMService()
				.getCertification(this.state.organizationDto?.domain)
				.then((certificationArn: string) => {
					if (!certificationArn)
						throw this.awsDomainError(
							translate(`${TRANSLATION_BASE_PATH}._ERROR_DOMAIN_NOT_FOUND`, {
								domain: this.state.organizationDto?.domain
							})
						);
					return new ACMService().getResourceRecordOfCertificate(
						certificationArn,
						getEnvironmentFromDomain(window.location.host),
						getSubdomain(this.state.organizationDto?.domain)
					);
				})
				.then((certificate: any) => {
					return this.setState({
						showModalInfoDNS: generateRequestCertificate,
						certificateCreated: certificate
					});
				});
		throw this.awsDomainError(
			translate(`${TRANSLATION_BASE_PATH}._ERROR_NOT_ALLOWED_TO_SEE_CERT`, {
				domain: this.state.organizationDto?.domain
			})
		);
	};

	toggleDrawer = () => {
		this.setState({
			drawerShown: !this.state.drawerShown
		});
	};

	onSubmit = (values: any, organizationUpdateAttribute: OrganizationUpdateAttribute, closeDrawer?: any) => {
		this.setState({ submitting: true });

		let organizationUpdate: Promise<any>;

		switch (organizationUpdateAttribute) {
			case OrganizationUpdateAttribute._ALL:
				organizationUpdate = this.updateOrganizationInfo(values);
				break;
			case OrganizationUpdateAttribute._DOMAIN:
				organizationUpdate = this.setDomainChangeRequest(values);
				break;
			case OrganizationUpdateAttribute._OWNER_EMAIL:
				organizationUpdate = this.setOwnerEmailChangeRequest(values);
				break;
		}

		return organizationUpdate
			.then(() => {
				return this.loadOrganizationProfile();
			})
			.finally(() => {
				this.setState({ submitting: false });
				if (closeDrawer) this.toggleDrawer();
			});
	};

	onOk = () => {
		this.setState({ showModalInfoDNS: false, certificateCreated: null });
	};

	async setDomainChangeRequest(values: any) {
		const { t: translate } = this.props;

		const domain = isReservedDomain(values.domain)
			? values.domain
			: getSubdomain(values.domain) === ""
				? `www.${values.domain}`
				: values.domain;
		await this.checkOldDomainToDelete(this.state.organizationDto?.domain);
		const { generateRequestCertificate, certificateCreated }: any = await this.checkNewDomainToCreate(domain).catch(
			(error: any) => {
				throw this.awsDomainError(error);
			}
		);

		return new OrganizationService()
			.updateDomainOrganization({
				...values,
				domain: domain,
				ownerEmail: this.state.organizationDto?.ownerEmail,
				name: this.state.organizationDto?.name,
				hasNewDomain: generateRequestCertificate,
				changedTypeOfDomain:
					isRequestCertificateRequired(this.state.organizationDto?.domain) !== generateRequestCertificate,
				sector: this.state.organizationDto?.sector
			})
			.then((result: any) => {
				showMessage(
					translate(`${TRANSLATION_BASE_PATH}._DOMAIN_REQUEST_SUCCESS`, {
						orgName: this.state.organizationDto.name
					})
				);
				this.setState({
					formInitialValues: {
						...this.state.formInitialValues,
						domain: domain
					},
					showModalInfoDNS: generateRequestCertificate,
					certificateCreated: certificateCreated,
					editDomainModalFormVisible: false
				});
				return result;
			});
	}

	private async checkOldDomainToDelete(domain: string) {
		const generateRequestCertificate: any = isRequestCertificateRequired(domain);
		if (generateRequestCertificate)
			return new ACMService().getCertification(domain).then((certificationArn: string) => {
				if (!certificationArn) return false;
				return new ACMService().deleteCertification(
					certificationArn,
					getEnvironmentFromDomain(window.location.host)
				);
			});
		return false;
	}

	private async checkNewDomainToCreate(domain: string) {
		const { t: translate } = this.props;
		const generateRequestCertificate: boolean = isRequestCertificateRequired(domain);
		let certificateCreated: any = null;

		if (generateRequestCertificate) {
			const isDomainUsed: boolean = await new OrganizationService().isDomainUsed(domain);
			if (isDomainUsed) throw translate(`${TRANSLATION_BASE_PATH}._ERROR_DOMAIN_EXIST`, { domain: domain });
			certificateCreated = await new ACMService()
				.requestCertificate(
					{ domain: domain, organizationName: this.state.organizationDto.name },
					getEnvironmentFromDomain(window.location.host),
					getSubdomain(domain)
				)
				.catch((error: any) => {
					throw error;
				});
		}
		return { generateRequestCertificate, certificateCreated };
	}

	private awsDomainError = (error: any) => {
		return this.setState(
			{
				submitting: false,
				loading: false,
				showModalInfoDNS: false,
				certificateCreated: null,
				editDomainModalFormVisible: false
			},
			() => {
				return showError(error);
			}
		);
	};

	setOwnerEmailChangeRequest(values: any) {
		const { t: translate } = this.props;
		return new OrganizationService().updateOwnerEmailoOrganization(values).then((result: any) => {
			showMessage(translate(`${TRANSLATION_BASE_PATH}._OWNER_CHANGE_REQUEST_SUCCESS`));
			this.setState({
				formInitialValues: {
					...this.state.formInitialValues,
					...values
				},
				editEmailModalFormVisible: false
			});
			return result;
		});
	}

	updateOrganizationInfo(values: any) {
		const { t: translate } = this.props;
		return new OrganizationService().updateOrganization(values).then((result: any) => {
			showMessage(translate(`${TRANSLATION_BASE_PATH}._UPDATE_SUCCESS`, { orgName: values.name }));
			return result;
		});
	}

	resendInvitation = async (invitationId: string) => {
		const { t: translate } = this.props;
		const invitation = await new InvitationService().resendInvitation(invitationId);
		if (invitation) showMessage(translate(`${TRANSLATION_BASE_PATH}._RESEND_REQUEST_SUCCESS`));
	};

	setEditDomainModalFormVisible = (status: boolean) => {
		this.setState({ editDomainModalFormVisible: status });
	};

	setEditEmailModalFormVisible = (status: boolean) => {
		this.setState({ editEmailModalFormVisible: status });
	};

	render() {
		const { t: translate } = this.props;
		const title = this.state.organizationDto && this.state.organizationDto.name;
		const smallTitle = this.state.organizationDto && this.state.organizationDto.code;
		const subTitle1 = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_1");
		const subTitle2 = translate("_ADMIN._LEFT_SIDE_MENU._ITEM_6_LONG");
		const cardTitleTeachers = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._TEACHERS`);
		const cardTitleClasses = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._CLASSES`);
		const cardTitleStudents = translate(`${TRANSLATION_BASE_PATH}._CARD_NAME._STUDENTS`);

		const menu = (
			<Menu>
				<Menu.Item key="0" onClick={() => this.onEdit()}>
					<Space direction="horizontal">
						<Icon type="ri-edit-line" />
						{translate(`${TRANSLATION_BASE_PATH}._MODIFY`)}
					</Space>
				</Menu.Item>
				<Menu.Item
					key="1"
					onClick={() => this.onViewCertification()}
					disabled={!this.state.organizationDto?.default}
				>
					<Space direction="horizontal">
						<Icon type="ri-shield-keyhole-line" />
						{translate(`${TRANSLATION_BASE_PATH}._VIEW_CERT_DETAILS`)}
					</Space>
				</Menu.Item>
				<Menu.Item
					key="2"
					onClick={() => this.onConnectCertification()}
					disabled={
						this.state.organizationDto?.certificationState !== "ISSUED" ||
						(this.state.organizationDto?.certificationState === "ISSUED" &&
							this.state.organizationDto?.certificationLinked === "CONNECTED")
					}
				>
					<Space direction="horizontal">
						<Icon type="ri-shield-check-line" />
						{translate(`${TRANSLATION_BASE_PATH}._CONNECT_CERT`)}
					</Space>
				</Menu.Item>
				<Menu.Item key="3" onClick={() => this.openDeleteConfirmationModal()}>
					<Space direction="horizontal">
						<Icon type="ri-delete-bin-line" />
						{translate(`${TRANSLATION_BASE_PATH}._DELETE`)}
					</Space>
				</Menu.Item>
			</Menu>
		);

		return (
			<>
				<SectionTitle
					canPersonalize={this.state.organizationDto.sector === OrganizationSectors.private}
					customizeUrl={`/${ADMIN_PATH}/${ORGANIZATIONS_PATH}/${this.state.organizationDto.id}/customize`}
					title={title}
					smallTitle={smallTitle}
					subtitle={[subTitle1, subTitle2, title]}
					subtitleUrl={[DASHBOARD_PATH, ORGANIZATIONS_PATH]}
					dropDownMenu={menu}
					certificationState={
						this.state.organizationDto?.certificationState === "ISSUED"
							? `${this.state.organizationDto?.certificationState}_${this.state.organizationDto?.certificationLinked}`
							: this.state.organizationDto?.certificationState
					}
				/>
				<SectionContent>
					<Row justify="space-between">
						<Col xs={24} sm={24} md={11} lg={11} xl={7} xxl={7}>
							<CounterCard
								title={cardTitleTeachers}
								icon="ri-user-2-line"
								data={this.state.cardTotalTeachersByOrganization}
								loading={this.state.loading}
							/>
						</Col>
						<Col xs={24} sm={24} md={11} lg={11} xl={7} xxl={7}>
							<CounterCard
								title={cardTitleClasses}
								icon="ri-door-open-line"
								data={this.state.cardTotalVirtualClassesByOrganization}
								loading={this.state.loading}
							/>
						</Col>
						<Col xs={24} sm={24} md={11} lg={11} xl={7} xxl={7}>
							<CounterCard
								title={cardTitleStudents}
								icon="ri-user-line"
								data={this.state.cardTotalStudentsByOrganization}
								loading={this.state.cardTotalStudentsByOrganizationLoading}
							/>
						</Col>
					</Row>
					<Row justify="end">
						<ResponsiveSearchBox placeholder={translate("_ADMIN._SEARCH_BOX._PLACEHOLDER")} onSearch={this.search} />
					</Row>
					<Row>
						<Col span={24}>
							<Skeleton loading={this.state.loading}>
								<AdminTable
									columns={this.columnsTable}
									dataSource={this.state.filteredData}
									locale={{
										emptyText: (
											<Empty
												image={Empty.PRESENTED_IMAGE_SIMPLE}
												description={translate(`${TRANSLATION_BASE_PATH}._TABLE._NO_DATA`)}
											/>
										)
									}}
								/>
							</Skeleton>
						</Col>
					</Row>
				</SectionContent>
				{this.state.organizationDto && (
					<EditDrawer
						toggleDrawer={this.toggleDrawer}
						drawerShown={this.state.drawerShown}
						organization={this.state.organizationDto}
						formInitialValues={this.state.formInitialValues}
						onSubmit={this.onSubmit}
						resendInvitation={this.resendInvitation}
						editDomainModalFormVisible={this.state.editDomainModalFormVisible}
						editEmailModalFormVisible={this.state.editEmailModalFormVisible}
						setEditEmailModalFormVisible={this.setEditEmailModalFormVisible}
						setEditDomainModalFormVisible={this.setEditDomainModalFormVisible}
						superAdmin
					/>
				)}
				<ModalInfoDNS
					onOk={this.onOk}
					certificateCreated={this.state.certificateCreated}
					showModal={this.state.showModalInfoDNS}
				/>
			</>
		);
	}
}

const Organization = withTranslation()(OrganizationComponent);
export default Organization;

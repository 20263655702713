import React, { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Collapse, Space, Empty, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { CaretRightOutlined } from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";

import { LessonItem } from "scenes/subjects/components/lessonItem/LessonItem";
import Icon from "../general/Icon";
import Text from "common/components/general/Text";
import { SUBJECTS_PATH } from "scenes/subjects";
import { LESSON_PATH } from "scenes/subjects/scenes/materialItems/scenes/lessonMaterial/LessonMaterial";
import { MATERIALS_PATH } from "scenes/subjects/scenes/materialItems";

const TRANSLATION_BASE_PATH = "_COMPONENTS._LESSON_LIST";

const { Panel } = Collapse;

interface StudentLessonListProps {
	lessonGroups: any[];
	lessons: any[];
	lessonsFinished: any[];
	isEnrolled: boolean;
	isMaterialPanel?: boolean;
	actualLessonId?: string;
	isVirtualClassroom?: boolean;
	groupedBy?: string | null;
	isPriced?: boolean;
	increase?: () => void;
}

const findActualTagGroupOfTheLesson = (lessonGroups: any, actualLessonId: any) => {
	const actualWeek = lessonGroups.find(
		(tagGroup: any) =>
			tagGroup.ClassroomTags[0]?.LessonClassroomTag.findIndex(
				(lessonToCheck: any) => lessonToCheck.LessonId === actualLessonId
			) > -1
	);

	const actualWeekIndex = actualWeek && lessonGroups.findIndex((tagGroup: any) => tagGroup.id === actualWeek.id);
	return actualWeekIndex && actualWeekIndex > -1 ? actualWeekIndex + 1 : 1;
};

export const StudentLessonList = (props: StudentLessonListProps) => {
	const history = useHistory();
	const { t: translate } = useTranslation();
	const [activeKey, setActiveKey] = useState([
		findActualTagGroupOfTheLesson(props.lessonGroups, props.actualLessonId)
	]);
	const [ungroupedLessons, setUngroupedLessons] = useState(Array);

	const changeActiveKey = (e: any) => {
		setActiveKey(e);
	};

	const onClickItem = (item: any) => {
		props?.increase?.();
		if (props.isEnrolled) {
			const isVirtualClass = props.isVirtualClassroom ? "?virtualClass=true" : "";
			const groupBy = props.isVirtualClassroom
				? props.groupedBy === ""
					? ""
					: `&groupedBy=${props.groupedBy}`
				: props.groupedBy === ""
				? ""
				: `?groupedBy=${props.groupedBy}`;
			return history.push(
				`/${SUBJECTS_PATH}/${MATERIALS_PATH}/${LESSON_PATH}/${item.id}${isVirtualClass}${groupBy}`
			);
		}
		if (props.isPriced) {
			return Modal.warning({
				content: translate(`${TRANSLATION_BASE_PATH}._BUY_COURSE`)
			});
		}
		return Modal.warning({
			content: translate(`${TRANSLATION_BASE_PATH}._NOT_ENROLLED`)
		});
	};

	const getLessonItems = (lessonItems: any[]): ReactNode => {
		return (
			<>
				{_(lessonItems)
					.orderBy((x: any) => x.lessonClassroomTagPriority, "asc")
					.value()
					.map((lesson: any, index: number) => {
						const isFinished =
							props.lessonsFinished &&
							props.lessonsFinished.findIndex(lessonFinished => lesson.id === lessonFinished.id) > -1;

						return (
							<Row
								key={index}
								className={
									"item-list " +
									(props.actualLessonId === lesson.id ? "active-item background-color-blue-6" : "")
								}
								align="middle"
							>
								<Col xs={22} sm={16} md={16} lg={16} xl={16} xxl={16}>
									<LessonItem
										key={index}
										id={lesson.id}
										canBeModified={false}
										lessonClassroomTagId={lesson.lessonClassroomTagId}
										name={lesson.name}
										lessonType={lesson.lessonType}
										endDate={lesson.endDate}
										isActualLesson={
											props.actualLessonId ? props.actualLessonId === lesson.id : false
										}
										textFontSize="14"
										textLineHeight="22"
										textWeight="semibold"
										onClick={e => {
											e.preventDefault();
											onClickItem(lesson);
										}}
									/>
								</Col>

								<Col xs={0} sm={6} md={6} lg={6} xl={6} xxl={6} className="pt-4">
									{lesson.endDate && (
										<Row justify="end" align="middle" className="full__height">
											<Text
												fontSize={"12"}
												lineHeight={"20"}
												className={
													props.actualLessonId === lesson.id ? "color-blue-1" : "color-gray-7"
												}
											>
												{moment(lesson.endDate).format("D MMMM")}
											</Text>
										</Row>
									)}
								</Col>
								<Col span={2}>
									{isFinished && (
										<Row justify="end">
											<Icon type="ri-checkbox-circle-fill" className="color-green-6" />
										</Row>
									)}
								</Col>
							</Row>
						);
					})}
			</>
		);
	};

	const getNoDataComponent = (): ReactNode => {
		return (
			<Row justify="center">
				<Col span={24}>
					<Empty
						image={Empty.PRESENTED_IMAGE_SIMPLE}
						description={translate(`${TRANSLATION_BASE_PATH}._NO_DATA_TO_CHECK`)}
					/>
				</Col>
			</Row>
		);
	};

	const geFinishedHeaderInfo = (name: string, lessons: any[]): ReactNode => {
		const finishedCheckedLessons =
			props.lessonsFinished && lessons?.length > 0
				? lessons.filter(
						(lessonToCheck: any) =>
							props.lessonsFinished?.findIndex((lesson: any) => lesson.id === lessonToCheck.id) > -1
				  )
				: [];

		return (
			<div className="flex__center_space_between">
				<Text fontSize={"16"} lineHeight={"24"} wheight="semibold">
					{name}
				</Text>
				<Text fontSize={"14"} lineHeight={"22"} className="color-blue-6">
					{finishedCheckedLessons.length}/{lessons.length}
				</Text>
			</div>
		);
	};

	const getGroupCard = (childTag: any, index: number): ReactNode => {
		return (
			<Row key={index + new Date().toString()} justify="center">
				<Col span={24}>
					<Collapse
						bordered={false}
						className={
							props.isMaterialPanel
								? "lessonList__course-view_student-material-panel background-color-gray-3"
								: "lessonList__course-view_student-async-list"
						}
						activeKey={activeKey}
						expandIcon={({ isActive }) => (
							<CaretRightOutlined className="color-gray-8" rotate={isActive ? 90 : 0} />
						)}
						onChange={e => changeActiveKey(e)}
					>
						<Panel
							className={childTag.name}
							header={geFinishedHeaderInfo(childTag.name, childTag.lessons)}
							key={index + 1}
						>
							{childTag.lessons?.length > 0 ? getLessonItems(childTag.lessons) : getNoDataComponent()}
						</Panel>
					</Collapse>
				</Col>
			</Row>
		);
	};

	const calculateUnGroupedItems = (lessonGroups: any[], lessons: any[]) => {
		const groupedLessons = lessonGroups?.flatMap((group: any) => group.ClassroomTags[0].LessonClassroomTag);
		const ungroupedLessson = lessons?.filter(
			(lesson: any) =>
				groupedLessons.findIndex((groupedLesson: any) => groupedLesson.LessonId === lesson.id) === -1
		);
		return _(ungroupedLessson)
			.orderBy((x: any) => x.name, "asc")
			.value();
	};

	useEffect(() => {
		const oldActiveKey = [...activeKey];
		setActiveKey([...oldActiveKey, findActualTagGroupOfTheLesson(props.lessonGroups, props.actualLessonId)]);
		const ungroupLessons: any[] = calculateUnGroupedItems(props.lessonGroups, props.lessons);
		setUngroupedLessons(ungroupLessons);
	}, [props.lessonGroups, props.lessons, props.actualLessonId]);

	return (
		<Space direction="vertical" className="full__width" size={props.isMaterialPanel ? 0 : 18}>
			{ungroupedLessons?.length > 0 &&
				getGroupCard(
					{ id: Date.now(), name: translate(`${TRANSLATION_BASE_PATH}._UNGROUP`), lessons: ungroupedLessons },
					0
				)}
			{props.lessonGroups?.map((childTag: any, index) =>
				getGroupCard(
					{
						id: childTag.id,
						name: childTag.name,
						lessons: childTag.ClassroomTags[0].LessonClassroomTag.flatMap((lessonTag: any) => {
							return {
								...lessonTag.Lesson,
								lessonClassroomTagId: lessonTag.id,
								lessonClassroomTagPriority: lessonTag.priority
							};
						}),
						classroomId: childTag.ClassroomTags[0].id
					},
					ungroupedLessons?.length > 0 ? index + 1 : index
				)
			)}
		</Space>
	);
};

StudentLessonList.defaultProps = {
	isMaterialPanel: false,
	isVirtualClassroom: false
};

/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { Component, createContext, LegacyRef, ReactInstance, ReactNode, useState } from 'react'
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import Button from "common/components/general/Button";
import { Layout, Space, Row, Col, Modal, Input } from "antd";
import { Link, Redirect } from "react-router-dom";
import Label from "common/components/dataEntry/components/Label";
import { SUBJECTS_PATH } from "scenes/subjects";
import { COURSE_PREVIEW_PATH } from "scenes/subjects/scenes/subjectItems/scenes/courseItem/CourseItem";
import menu from 'antd/lib/menu';
import _, { iteratee } from "lodash";
import styled from "styled-components";
import ClassSlider, { ClassItem } from 'scenes/main/components/classSlider/ClassSlider';
import { Card } from "antd";
import { MainHeader as StudentHeader } from "common/components/layout/header";
import { MainDashboardMenuItems } from"../main/components/layout/Layout"
import Image from "common/components/general/Image";
import TagService from 'services/administration/tag.services';
import certifikata from "assets/images/icons/certifikata.png"
import ClassroomService from 'services/administration/classroom.service';
import LessonDto from 'dtos/administration/lesson.dto';
import { SocialIcon } from 'react-social-icons';
import ClassroomDto from "dtos/administration/classroom.dto";
import { JWTUserInfoDto } from 'dtos/authentication/token/jwtUserInfo.dto';
import UserDto from 'dtos/authentication/user/user.dto';
import EnrollmentService from 'services/administration/enrollment.service';
import UserService from 'services/authentication/user.service';
import OAuth2Service from 'services/authentication/oauth2.service';
import moment from 'moment';
import UserFullnameDto from 'dtos/authentication/user/useFullName.dto';
import { userInfo } from 'os';
import { UserAvatar } from 'common/components/general/UserAvatar';
import { LiveClassIcon } from 'scenes/PublicMarketplace/_courseView/components/LiveClassIcon';
import { CourseItem } from 'scenes/PublicMarketplace/CourseItem';
import ReactToPrint from 'react-to-print';
import { SectionCourses } from 'scenes/PublicMarketplace/SectionCourses';
import Organization from 'scenes/main/scenes/organization';
import { PriceTag } from 'scenes/PublicMarketplace/_courseView/components/PriceTag';
import { CourseView } from 'scenes/PublicMarketplace/_courseView';
import row from 'antd/lib/row';
import { FeedDto } from 'dtos/administration/feed.dto';
import EnrollmentDto from 'dtos/administration/enrollment.dto';
import { COURSE_PATH } from 'scenes/course';
import ReactPDF, { PDFViewer } from '@react-pdf/renderer';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

import { ComponentToPrint } from './ComponentToPrint ';

import { individualClassroomFormValidator } from 'scenes/courseBuilder/scenes/courseDetails/components/layout/ValidationSchema';
import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	WhatsappShareButton,
	InstapaperShareButton
	
} from "react-share";
import { ref } from 'yup';


const { Header, Sider, Content } = Layout;
const NAVIGATE_URL = "/subjects/course/";
const currentUserInfo: JWTUserInfoDto | null = OAuth2Service.CurrentUser;

let show: {[show: string]: any} = {};

type ChildProps = {
	setCourse: any;
	teacherName?: string;
	setActualLessonId: any;
	nextLesson: any;
	lessonsFinished: any;
	lassroom: ClassroomDto | undefined;
	classroomEnrolled: any;
	setBackUrl: any;
	setSelectedTag: any;
	groupedBy: any;
	ClassPendingImg: any;
    enrollmentId: string;
	row: any;
    isClicked: any;
   
};


export const ChildContext = ({
	setCourse: null,
	setActualLessonId: null,
	nextLesson: null,
	lessonsFinished: [],
	enrollmentId: "",
	classroomEnrolled: null,
	setBackUrl: null,
	setSelectedTag: null,
	groupedBy: null,
	ClassPendingImg: null,


});

interface AppState {
	showPopup: false;
	visible:any;
	title: any;
	teacherName?: string;
    ref: any;
		pdfExportComponent: any;
	newProperty: any;
	componentRef: any;
	
	
}
export class Certificate extends Component< any, any> {
	pdfExportComponent: any;
	newProperty: any;
     current : any;
	componentRef: any;
	
	 

	
	

	retriveLessonsFromTree(): LessonDto[] | null {
		throw new Error('Method not implemented.');
	}
	getPhotoUrls(items: { id: string; name: string; organizationName: string; teacherName: string; isAsync: boolean; pathToClassroom: string; File: import("../../dtos/administration/file.dto").default | undefined; }[]): ClassItem[] | PromiseLike<ClassItem[]> {
		throw new Error('Method not implemented.');
	}
 
	
    
	slider: any;
   x: any;
	constructor(props: any) {
   
		super(props);
	
		this.state = {
			
		    exportPDFWithComponent: "",
			save: "",
			handleExportWithComponent: "",
			isModalOpen: false,
			lessons: [],
			rootLessonTags: [],
			lessonTagGroup: [],
			tabActiceKey: "0",
			collapsed: true,
			siderFullWidth: false,
			courseId: "",
			actualLessonId: "",
			goToDashboard: false,
			isRegistered: true,
			enrollmentId: "",
			lessonsFinished: [],
			backURL: `/${SUBJECTS_PATH}/${COURSE_PREVIEW_PATH}/`,
			isVirtualClass: false,
			isAssignmentSection: false,
			groupedBy: null,
			showResults: true,
			Label: "",
			classroom: undefined,
			width: 10,
			percent: 10,
			showButton: true,
            currentUserInfo: "",
			x: "",
            imagePrefix: "",
			showEnrolledUsers: "",
			isClicked: false,
		 
			
		};
        
	  
		this.setActualLessonId = this.setActualLessonId.bind(this);
		this.retriveLessonsFromTree = this.retriveLessonsFromTree.bind(this);
		this.getCircleDropDownButton = this.getCircleDropDownButton.bind(this);
		this.setBackUrl = this.setBackUrl.bind(this);
	   
	}


	showModal = () => {
		this.setState({
			isModalOpen: true
		});
	};
	
	handleOk = () => {
		this.setState({
			isModalOpen: false
		});
	};
	
	handleCancel = () => {
		this.setState({
			isModalOpen: false
		});
	};
	  

		reactToPrintContent = () => {
			return this.componentRef;
		  };
		
    setSelectedTag = async (groupedBy: string, classroomId: string) => {
		const rootLessonTags: any = await new TagService().getAllRootTagsforLessons();
		const selectedTag = rootLessonTags.findIndex((tag: any) => tag.name === groupedBy);
		const lessonTagGroup = await new TagService().getAllLessonClassroomTags(
			rootLessonTags[selectedTag > -1 ? selectedTag.toString() : "0"]?.id,
			classroomId
		);

		return this.setState({
			tabActiceKey: selectedTag > -1 ? selectedTag.toString() : "0",
			rootLessonTags: rootLessonTags,
			lessonTagGroup: _(lessonTagGroup)
				.orderBy((x: any) => Number(x?.ClassroomTags[0]?.priority), "asc")
				.value()
		});
	};

	
    
	

    setBackUrl = async (
		url: string,
		isVirtualClass: boolean,
		isAssignmentSection: boolean,
		groupedBy: string | null
	) => {
		const rootLessonTags: any = await new TagService().getAllRootTagsforLessons();
		const selectedTag = rootLessonTags.findIndex((tag: any) => tag.name === groupedBy);
		this.setState({
			backURL: url,
			rootLessonTags: rootLessonTags,
			isVirtualClass: isVirtualClass,
			isAssignmentSection: isAssignmentSection,
			tabActiceKey: selectedTag > -1 ? selectedTag : "0",
			groupedBy: groupedBy
		});
	};

	setActualLessonId = (actualLessonId: string) => {
		this.setState({ actualLessonId: actualLessonId });
	};
  
	

	
	
	getItem(item: any): ReactNode {
		return (
			<Card hoverable={true} className="box_shadow pb-8" bordered={false} bodyStyle={{ padding: "0" }}>
				<Link to={NAVIGATE_URL + item.id}>
					<Row className="mb-8">
						{this.getPhoto(item)}
						{!item.isAsync && <LiveClassIcon />}
					</Row>

					<Row className="ml-16">
						<Text
							tooltipOnEllipsis={true}
							tooltip={item.name}
							fontSize="16"
							lineHeight="24"
							className="color-gray-9 marketplace__full-width-ellipsis"
						>
							{item.name}
						</Text>
					</Row>

					<Row className="pt-8 ml-16">
						<Col span={24}>
							<Text fontSize="12" lineHeight="20" className="marketplace__organization__name">
								{item.Organization?.name}
							</Text>
						</Col>
					</Row>
					<Row className="mt-8 ml-16">
						<Col span={24}>
							<Space direction="horizontal" size={4} align="center">
								{  item.TotalEnrollments >= 10 ? (
									<>
										<Icon type="ri-user-line" className="color-gray-8" fontSize="14" />
										<Text fontSize="14" lineHeight="22" className="color-gray-8">
											{item.TotalEnrollments}
										</Text>
									</>
								) : (
									""
								)}
							</Space>
						</Col>
					</Row>
					<PriceTag price={item.price} />
				</Link>
			</Card>
		);
	}
	getPhoto(item: any): string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined {
		throw new Error('Method not implemented.');
	}

	onCardClick = (): void => {
		this.setState({ isClicked : true}) 
	 }

	getClassItemsOverviewDetails = async (data: EnrollmentDto[]): Promise<ClassItem[]> => {
		const items = data
			.filter((x: EnrollmentDto) => x.status === "ACTIVE")
			.map((x: EnrollmentDto) => {
				let coursePath = "";
				if (x.Classroom?.isAsync) {
					coursePath = `/${SUBJECTS_PATH}/${COURSE_PREVIEW_PATH}/${x.ClassroomId}`;
				} else {
					coursePath = `/${COURSE_PATH}/_student/${x.ClassroomId}`;
				}
				return {
					id: x.ClassroomId,
					name: x.Classroom?.name ?? "",
					organizationName: x.Classroom?.Organization?.name ?? "",
					teacherName: x.Classroom?.UserCreatedBy?.firstName + " " + x.Classroom?.UserCreatedBy?.lastName,
					isAsync: x.Classroom?.isAsync ?? true,
					pathToClassroom: coursePath,
					File: x.Classroom?.File
				};
			});
            return this.getPhotoUrls(items)
	
	};



	getCircleDropDownButton = (): React.ReactNode => {
		return (
			<Button
				type="default"
				shape="circle"
				className="materialItem__layout materialItem__button-circle color-gray-8"
			>
				<Icon type="ri-more-2-line" />
			</Button>
		);
	};

	async isACTIVEEnrollment(enrollmentId: string) {
		return new EnrollmentService().getEnrollmentById(enrollmentId).then((enrollment: any) => {
			if (enrollment.status !== "ACTIVE") {
				window.location.href = "/";
			}
			return;
		});
	}

	getPhotoOf(): string | number | boolean | {} | React.ReactNodeArray | React.ReactPortal | React.ReactElement<any, string | React.JSXElementConstructor<any>> | JSX.Element | null | undefined {
		return (
			<Image
				className="marketplace__classSlider__item__card__image"
				src={""}
			/>
		);
	}

	

 render()



  
   {
   
	
	
    return(
		
			<><div>

      <div className='newsection'>
        <ReactToPrint
          trigger={() => {
            return  <button className='new'> <Icon type="ri-download-fill" className='icondownload' />Sharko këtu</button>;
          }}
        
          content={() => this.componentRef}
        />
         	<Button onClick={this.showModal} className='ndaje'>Ndaje
							<Icon type="ri-share-line"className='iconshare' />

						</Button>
						<a className="claas"><b>Përditëso të dhënat e tua</b></a>
		</div>
        <ComponentToPrint ref={el => (this.componentRef = el)} />
      
			<Layout>
				<StudentHeader menu={MainDashboardMenuItems} showSwitchRole={true} />
				{this.props.children}
			</Layout>

			
			<Text className="tretre" fontSize="11"> <b>Marrësi i Çertifikatës:</b></Text>
			<Text className="trekater" fontSize="11"> <b>Mbi Kursin:</b></Text>
			<Text className="teksti"fontSize='11' > <b>
            Kjo certifikatë vërteton që <a> {currentUserInfo?.User?.firstName + " " + currentUserInfo?.User?.lastName}</a> 
			përfundoi me sukses kursin <a>How to Design & Prototype in Adobe XD</a> më 01/07/2021<br/> nga instruktori<a> Akademi Pro </a>në Akademi.al. </b></Text>
			<Text className="lastclass"fontSize='11' > <b>
           <a>Përditëso të dhënat e tua </a></b></Text>  
			<Space>
            <div className="loggedInMenu__user">

					<Text fontSize="16" lineHeight="22" className="username">
						<Space>
							<span className="loggedInMenu__fullnameE">

								{currentUserInfo?.User?.firstName + " " + currentUserInfo?.User?.lastName}
							</span>

						</Space>
					</Text>
				</div>
			</Space>
		</div><div className="courseItem">
				<Row className="mb-24">
					<Col span={24} className="flex__center_justify_center text__align_center">

						<Text fontSize="20" lineHeight="28" className="color-black">
							<b>Matematika 12</b>
						</Text>
						<Text fontSize="14" lineHeight="28" className="color-gray1">
							Sami Frashëri
						</Text>
						<Text fontSize="14" lineHeight="28" className="color-gray2">
							24 Leksione
						</Text>
						

					

					</Col>
				</Row>
				<Modal title="Share certificate via" visible={this.state.isModalOpen} onOk={this.handleOk} onCancel={this.handleCancel}>
					<FacebookShareButton url="http://akademia.al"
						quote='I got Certificated in Akademia.al'
						hashtag='#akademia.al'>
						<SocialIcon className="iconsS" url="https://facebook.com" />
					</FacebookShareButton>
					<TwitterShareButton url="http://akademia.al"

					>
						<SocialIcon className="iconsS" url="https://twitter.com/jaketrent" />
					</TwitterShareButton>
					<InstapaperShareButton url="http://akademia.al"

					>
						<SocialIcon className="iconsS" url="https://instagram.com" />
					</InstapaperShareButton>
					<LinkedinShareButton url="http://akademia.al"

					>
						<SocialIcon className="iconsS" url="https://linkedin.com" />
					</LinkedinShareButton>
					<WhatsappShareButton url="http://akademia.al"

					>
						<SocialIcon className="iconsS" url="https://whatsapp.com" />
					</WhatsappShareButton>
					<br />
					<Text fontSize='14' className='linklink'><b>or copy link</b></Text>
					<Input
						addonAfter={<Icon type="ri-link"></Icon>}
						className="inputlink"
						name="facebook"
						value="www.facebook.com"
						disabled />
					<br />

				</Modal>

				<CourseItem />

			</div></>
       
    )
  }


}
export default Certificate;






import ApiServiceData from "../api.service";

const relativeUrl = "/v1/api/subject-plan";

export default class SubjectPlanService extends ApiServiceData {
    getSubjectPlanTreeBySubjectId(id: string): import("../../dtos/administration/subjectPlanTree.dto").default | PromiseLike<import("../../dtos/administration/subjectPlanTree.dto").default | null> | null {
        throw new Error('Method not implemented.');
    }
	public async createSubjectPlanForClassroom(name: string, classroomId: string) {
		return this.post(relativeUrl, {
			name: name,
			ClassroomId: classroomId
		}).then(res => {
			return res.data;
		}).catch(error => {
			throw error.response;
		});;
	}
}

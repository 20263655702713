import React from "react";
import { Switch, Route } from "react-router-dom";
import MainLayout from "scenes/courseBuilder/scenes/buildMaterials/components/layout/Layout";
import LessonMaterial, {
	LESSON_FULL_PATH
} from "scenes/courseBuilder/scenes/buildMaterials/scenes/lessonMaterial/LessonMaterial";
import QuizMaterial, {
	QUIZ_FULL_PATH
} from "scenes/courseBuilder/scenes/buildMaterials/scenes/quizMaterial/QuizMaterial";
import { COURSE_BUILDER_PATH } from "scenes/courseBuilder";
import MeetingMaterial, { MEETING_FULL_PATH } from "./scenes/meetingMaterial";
import AssignmentsMaterial, { ASSIGNMENT_FULL_PATH } from "./scenes/assignmentsMaterial/AssignmentsMaterial";
import PdfMaterial, { PDF_FULL_PATH } from "./scenes/pdfMaterial/PdfMaterial";

export const MATERIALS_PATH = "materials";

const Main = () => {
	let onSubmitTriggerHandler: () => Promise<any>;

	return (
		<MainLayout onSubmitTrigger={() => onSubmitTriggerHandler()}>
			<Switch>
				<Route
					path={`/${COURSE_BUILDER_PATH}/${MATERIALS_PATH}/${LESSON_FULL_PATH}`}
					exact
					render={props => (
						<LessonMaterial
							{...props}
							handleOnSubmit={(submitForm: any) => {
								onSubmitTriggerHandler = submitForm;
							}}
						/>
					)}
				/>
				<Route
					path={`/${COURSE_BUILDER_PATH}/${MATERIALS_PATH}/${QUIZ_FULL_PATH}`}
					exact
					render={props => (
						<QuizMaterial
							{...props}
							handleOnSubmit={(submitForm: any) => {
								onSubmitTriggerHandler = submitForm;
							}}
						/>
					)}
				/>
				<Route
					path={`/${COURSE_BUILDER_PATH}/${MATERIALS_PATH}/${MEETING_FULL_PATH}`}
					exact
					render={props => (
						<MeetingMaterial
							{...props}
							handleOnSubmit={(submitForm: any) => {
								onSubmitTriggerHandler = submitForm;
							}}
						/>
					)}
				/>
				<Route
					path={`/${COURSE_BUILDER_PATH}/${MATERIALS_PATH}/${ASSIGNMENT_FULL_PATH}`}
					exact
					render={props => (
						<AssignmentsMaterial
							{...props}
							handleOnSubmit={(submitForm: any) => {
								onSubmitTriggerHandler = submitForm;
							}}
						/>
					)}
				/>
				<Route
					path={`/${COURSE_BUILDER_PATH}/${MATERIALS_PATH}/${PDF_FULL_PATH}`}
					exact
					render={props => (
						<PdfMaterial
							{...props}
							handleOnSubmit={(submitForm: any) => {
								onSubmitTriggerHandler = submitForm;
							}}
						/>
					)}
				/>

				
			</Switch>
		</MainLayout>
	);
};

export default Main;

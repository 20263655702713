import { Card, Col, Row } from "antd";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { FullScreenModal } from "../../../../common/components/general/FullScreenModal";
import Icon from "common/components/general/Icon";
import Text from "common/components/general/Text";
import { useStoreActions, useStoreState } from "easy-peasy";
import { ItemFilters } from "./ItemFilters";
import { ActiveFilters } from "../../scenes/subjectItems/components/activeFilters";

interface ModalItemFiltersProps {
	toggleModal: (toggle: boolean) => void;
}

export const ModalItemFilters = function(props: ModalItemFiltersProps) {
	const { t: translate } = useTranslation();
	const TRANSLATION_BASE_PATH = "_SUBJECTS._ITEM_FILTERS_MODAL";
	const filters = useStoreState(state => state.subjects.activeFilters);
	const updateFilters = useStoreActions((actions: any) => actions.subjects.updateFilters);
	const allItems = useStoreState(state => state.subjects.allItems);
	const totalItems = useStoreState(state => state.subjects.totalItems);

	function resetFilters() {
		updateFilters({ filters: [], items: allItems });
	}

	function HeadModal(): ReactNode {
		return <Card className="modal-item-filters__head">
			<Row align={"middle"} justify={"space-between"}>
				<Col>
					<Text fontSize={"16"} lineHeight={"24"} wheight="semibold">
						{translate(`${TRANSLATION_BASE_PATH}._MODAL_TITLE`)}
					</Text>
				</Col>
				<Col>
					<Icon fontSize={"24"} type="ri-close-fill" onClick={() => props.toggleModal(false)} />
				</Col>
			</Row>
		</Card>;
	}

	function BodyModal(): ReactNode {
		return <Card className="modal-item-filters__body">
			<ItemFilters modalPresentation parentTag={undefined} />
		</Card>;
	}

	function FooterModal(): ReactNode {
		return <Card className={"modal-item-filters__footer"}>
			{filters.length > 0 && <div className="modal-item-filters__tags">
				<ActiveFilters hideClear hideResults noPadding/>
			</div>}
			<Row justify={"space-between"}>
				<Col>{totalItems} results</Col>
				
				{filters.length > 0 && <Col>
					<Text
						fontSize="12"
						lineHeight="20"
						onClick={() => resetFilters()}
						className="cursor_pointer color-blue-6 pt-4"
					>
						Hiq filtrat
					</Text>
				</Col>}
			</Row>
		</Card>;
	}


	return <div className="modal-item-filters">
		<FullScreenModal head={HeadModal()} body={BodyModal()} footer={FooterModal()} />
	</div>;
};
import React from "react";
import { Button, Col, Row, Skeleton, Space } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Text from "common/components/general/Text";
import ClassSlider from "./components/ClassSlider";
import { turnNumberToRange } from "common/utils/NumberUtils";

const TRANSLATION_BASE_PATH = "_MARKETPLACE";
const showEnrolledUsers = false;

export const CourseView = (props: any) => {
	const { t: translate } = useTranslation();

	props.items.map((item: any) => {
		if (item?.TotalEnrollments < 500) item.TotalEnrollments = turnNumberToRange(item?.TotalEnrollments, 500, 1000);
		return item;
	});
	const items = _.orderBy(props.items, (x: any) => x?.TotalEnrollments, "desc");

	const width = window.innerWidth;

	return (
		<CourseViewWrapper>
			<Skeleton loading={props.loading}>
				<Row className="mt-16 course-view">
					<Col span="24">
						<Row className="header">
							<Col span="12">
								<Space>
									<Text
										fontSize={width <= 350 ? "14" : width <= 500 ? "16" : "24"}
										lineHeight="38"
										wheight="semibold"
									>
										{props.title}
									</Text>
									<Text
										fontSize={width <= 350 ? "14" : width <= 500 ? "16" : "24"}
										lineHeight="38"
										wheight="regular"
									>
										{props.emoji}
									</Text>
								</Space>
							</Col>

							<Col span="12">
								<Row justify="end" align="middle" style={{ height: "100%" }}>
									<Button size="small" type="default" onClick={props.showAllClick}>
										{translate(`${TRANSLATION_BASE_PATH}._SHOW_ALL`)}
									</Button>
								</Row>
							</Col>
						</Row>
						{items && items.length > 0 ? (
							<>
								<ClassSlider
									items={items}
									imagePrefix={props.imagePrefix}
									expandAll={false}
									showEnrolledUsers={showEnrolledUsers}
								/>
							</>
						) : (
							<Text fontSize={width <= 500 ? "12" : "16"} wheight="regular">
								{translate(`${TRANSLATION_BASE_PATH}._NO_COURSES`)}
							</Text>
						)}
					</Col>
				</Row>
			</Skeleton>
		</CourseViewWrapper>
	);
};

const CourseViewWrapper = styled.div`
	.course-view {
		.header {
			margin-bottom: 19px;
		}
	}
`;


import React, { ReactNode } from "react";
import Text from "common/components/general/Text";
import certifikata from "assets/images/icons/certifikata.png"
import { JWTUserInfoDto } from "dtos/authentication/token/jwtUserInfo.dto";
import OAuth2Service from 'services/authentication/oauth2.service';
import Image from "common/components/general/Image";
import UserFullnameDto from "dtos/authentication/user/useFullName.dto";

type NewType = JWTUserInfoDto;

const currentUserInfo: NewType | null = OAuth2Service.CurrentUser;


export class ComponentToPrint extends React.PureComponent < any, any>{
static UserCreatedBy: UserFullnameDto | null;



    render(){
     return(
        <div>
          <Image src={certifikata} className="certifikata" />

          <Text className='textPjs' fontSize="20">Çertifikatë Pjesëmarrje{}</Text>
          <Text className='textdy' fontSize="20">Kjo çertifikatë i jepet <b>
            {currentUserInfo?.User?.firstName + " " + currentUserInfo?.User?.lastName}</b> për kryerjen e Kursit  me <b>10 Qershor 2020.</b> </Text>
          <Text className='tre' fontSize="11">	<Text fontSize="14" lineHeight="24" className="color-gray-8">
          </Text><b>John Doe{UserFullnameDto}</b><br />Instruktor</Text>
       
          
        </div>
     );
    
    }
  }
  const pageStyle = `
  @page {
    size: 80mm 80mm;
  }

  @media all {
  display:none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: never;
    }
    .pagesize {
      size: A3;
    }
  }
`;


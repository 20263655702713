import React from "react";

import { Space, Row, Col } from "antd";
import styled from "styled-components";
import Text from "common/components/general/Text";
import Icon from "common/components/general/Icon";
import { CourseItem } from "./CourseItem";
import { Pagination } from "./Pagination";
interface CourseGroupProps {
	items: any[];
	title: string;
	emoji?: string;
	orgDetails?: any,
	total: number;
	current: number;
	onPaginationChange: Function;
	icons?: string;
}

export const CourseGroup = (props: CourseGroupProps) => {
	return (
		<CourseGroupStyledWrapper>
			<Space>
				<Text fontSize="24" lineHeight="32" wheight="semibold">
					{props.title}
				</Text>
			</Space>
			<div>
				<div className="pt-8 pb-4"><Text fontSize="16" lineHeight="20" wheight="regular">{props?.orgDetails?.subtitle}</Text></div>
				<div><Text fontSize="12" wheight="regular">{props?.orgDetails?.about}</Text></div>

				<div className="icons">
					<Row>
						<Col>
						{props?.orgDetails?.facebook && (
							<div className="fafa">
								<a href={props?.orgDetails?.facebook} target="_blank" rel="noopener noreferrer"><Icon fontSize="20" type="ri-facebook-line"></Icon></a>
							</div>
						)}
						{props?.orgDetails?.twitter && (
							<div className="fafa">
								<a href={props?.orgDetails?.twitter} target="_blank" rel="noopener noreferrer"><Icon fontSize="20" type="ri-twitter-line"></Icon></a>
							</div>
						)}
						{props?.orgDetails?.instagram && (
							<div className="fafa">
								<a href={props?.orgDetails?.instagram} target="_blank" rel="noopener noreferrer"><Icon fontSize="20" type="ri-instagram-line"></Icon></a>
							</div>
						)}
						</Col>
					</Row>
				</div>
			</div>
			{props.items.length > 0 && (
				<CourseItem {...props.items[0]} />
			)}
			{props.items.length > 1 && (
				<>
					<div className="courses-wrapper">
						{props.items.slice(1).map((item, itemIndex) => (
							<CourseItem {...item} key={itemIndex} />
						))}
					</div>

					{props.total / 9 > 1 && (
						<div className="pagination-wrapper">
							<Pagination
								showSizeChanger={false}
								defaultCurrent={0}
								total={props.total}
								current={props.current}
								onChange={props.onPaginationChange}
								pageSize={9}
							/>
						</div>
					)}
				</>
			)}
			{props.items.length === 0 && <div>Nuk ka kurse</div>}
		</CourseGroupStyledWrapper >
	);
};


const CourseGroupStyledWrapper = styled.div`
	.courses-wrapper {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
		gap: 29px 14px;
		margin-top: 19px;
	}

	.pagination-wrapper {
		margin-top: 57px;
	}

	.icons {
		color: #0085FF;
        display: inline-block;
        text-align: right;
        padding: 1rem 0;
		margin-bottom: 1rem;
		font-size: initial;
	}

	.fafa {
		display: inline-block;
		margin-right: 1em;
	}
`;

import { Col, Divider, Row, Skeleton } from "antd";

import React, { useEffect, useState } from "react";
import Button from "common/components/general/Button";

import TagService, { TYPE_CATEGORY } from "services/administration/tag.services";
import Text from "common/components/general/Text";
import TagDto from "../../dtos/administration/tag.dto";
import { useTranslation } from "react-i18next";

interface ItemFiltersProps {
	activeFilters?: string;
	setActiveFilters: (category?: string) => void;
}

export const ItemFilters = (props: ItemFiltersProps) => {
	
	const { t: translate } = useTranslation();
	const basepath = "_MARKETPLACE._ITEM_FILTERS";
	const MAX_TAGS = 20;
	const [tagsContainer, setTagContainer] = useState<{ [key: string]: { allTags: TagDto[]; subTags: TagDto[] } }>({});
	const [parents, setParents] = React.useState<TagDto[]>([]);
	const [loadingTags, setLoadingTags] = React.useState<boolean>(false);
	const [activeFilters, setActiveFilters] = useState<string | undefined>(props.activeFilters);

	useEffect(() => {
		props.setActiveFilters(activeFilters);
	}, [activeFilters]);

	const setFilters = (e: string) => {
		if (e === activeFilters) {
			setActiveFilters(undefined);
		} else {
			setActiveFilters(e);
		}
	};

	const loadTags = async () => {
		try {
			setLoadingTags(true);
			const tagService = new TagService();
			const response: TagDto[] = await tagService.getAllForPublic({
				organization: "e47c7a13-83e8-41a0-afcc-3a8fd0fbda65",
				type: TYPE_CATEGORY
			});

			const parents = response.filter((item: any) => item.ParentId === null);
			setParents(parents);

			parents.forEach(tag => {
				const allTags = response.filter((subtag: TagDto) => subtag.ParentId === tag.id);
				tagsContainer[tag.id] = {
					allTags,
					subTags: allTags.slice(0, MAX_TAGS)
				};
			});
			setTagContainer({ ...tagsContainer });

			setLoadingTags(false);
		} catch (err) {
			setLoadingTags(false);
		}
	};

	useEffect(() => {
		loadTags();
	}, []);

	const updateShowMoreTag = (tagId: string) => {
		setTagContainer({
			...tagsContainer,
			[tagId]: {
				subTags: tagsContainer[tagId].allTags,
				allTags: tagsContainer[tagId].allTags
			}
		});
	};

	return (
		<Row className="pt-16">
			<Col span="24">
				<Skeleton loading={loadingTags} active>
					{parents?.map((tag: TagDto, index: number) => {
						return (
							<div key={index}>
								<Row>
									<Col className="pt-0">
										<Text
											fontSize="12"
											lineHeight="20"
											wheight="semibold"
											className="color-gray-9 pr-8 pl-8"
										>
											{tag.name.toUpperCase()}
										</Text>
									</Col>
								</Row>
								<Divider className="pb-16 m-0 mt-4" />
								<Row>
									{tagsContainer[tag.id] &&
										tagsContainer[tag.id].subTags.map((childTag: any) => {
											const classStyle =
												props.activeFilters === childTag.id
													? "itemFilters__selected__filter"
													: "";
											return (
												<>
													<Col
														key={childTag.id}
														xs={24}
														sm={24}
														md={24}
														lg={24}
														xl={24}
														xxl={24}
														onClick={() => setFilters(childTag.id)}
														className={`cursor_pointer mb-4 pl-8 pr-8 pt-4 pb-4 mr-4 ${classStyle}`}
													>
														<Text fontSize="14" lineHeight="22">
															{childTag.name}
														</Text>
													</Col>
												</>
											);
										})}
									{tagsContainer[tag.id] &&
										tagsContainer[tag.id].allTags.length > tagsContainer[tag.id].subTags.length && (
											<Button type="link" onClick={() => updateShowMoreTag(tag.id)}>
												{translate(
													basepath +
														"._SHOW_MORE" +
														(tagsContainer[tag.id].allTags.length -
															tagsContainer[tag.id].subTags.length >
														1
															? "_many"
															: "_one"),
													{
														count:
															tagsContainer[tag.id].allTags.length -
															tagsContainer[tag.id].subTags.length
													}
												)}
											</Button>
										)}
								</Row>
								<Row className="mb-16"></Row>
							</div>
						);
					})}
				</Skeleton>
			</Col>
		</Row>
	);
};

import React, { useState, useEffect } from "react";
import { Skeleton } from "antd";
import { useStoreActions } from "easy-peasy";
import ClassroomService  from "services/administration/classroom.service";
import { CourseGroup } from "./CourseGroup";

import { MarketplaceStore, useMarketplaceContext } from "./PublicMarketplace";
import { useTranslation } from "react-i18next";

type OrgDetails = {
	subtitle?: string;
	about?: string;
	facebook?: string;
	twitter?: string;
	instagram?: string;
}

export const AllCourses = () => {
	const [state, setState] = useState({
		items: [],
		total: 0,
		current: 1,
		loading: true
	});
	const { marketplaceStore: [marketplaceState, setMarketplaceState] } = useMarketplaceContext() as { marketplaceStore: [MarketplaceStore, any] };
	const { t: translate } = useTranslation();
	const updateTotalCoursesOnPublicMarketPlace = useStoreActions((actions: any) => actions.subjects.updateTotalCoursesOnPublicMarketPlace);

	const setItems = (items: any) => setState(state => ({ ...state, items }));
	const setLoading = (loading: any) => setState(state => ({ ...state, loading }));
	const setTotal = (total: any) => setState(state => ({ ...state, total }));

	const onPaginationChange = (current: number) => setState(state => ({ ...state, current }));

	const handleSearchProgress = (loading: boolean) => {
		setMarketplaceState((marketplaceState: any) => ({
			...marketplaceState,
			loading
		}));
	};

	const loadFeaturedCourses = async () => {
		setLoading(true);
		handleSearchProgress(true);

		const classromSerview: any = new ClassroomService();
		try {
			const response = await classromSerview.classroomsSearch({
				...(marketplaceState.activeOrganization && { organization: marketplaceState.activeOrganization }),
				order: "asc",
				limit: 9,
				page: state.current,
				tags: marketplaceState.rightFilters,
				category: marketplaceState.category,
				subject: marketplaceState.subject,
				query: marketplaceState.searchKey,
				...(marketplaceState.group && marketplaceState.group !== "all" && { [marketplaceState.group]: true }),
			});

			setItems(response.items);
			setTotal(response.total);
			updateTotalCoursesOnPublicMarketPlace({ group: "all", number: response.total });
			setLoading(false);
			handleSearchProgress(false);
		} catch (err) {
			setLoading(false);
			handleSearchProgress(false);
		}
	};

	const getComputedName = (): string | string[] => {
		if (marketplaceState.subject) {
			return marketplaceState.subjects.find((s: any) => s.id === marketplaceState.subject)?.name ?? "";
		}

		if (marketplaceState.activeOrganization) {
			return marketplaceState.organizations.find((s: any) => s.id === marketplaceState.activeOrganization)?.name ?? "";
		}

		if (marketplaceState.group) {
			return translate("_MARKETPLACE._COURSE_CATEGORIES._" + marketplaceState.group?.toUpperCase()).split(" ");
		}

		return "";
	};

	const getOrgDetails = (): OrgDetails => {
		let orgDetails = {
			subtitle: "",
			about: "",
			facebook: "",
			twitter: "",
			instagram: "",
		}

		if (marketplaceState.activeOrganization) {
			const org = marketplaceState.organizations.find((s: any) => s.id === marketplaceState.activeOrganization);

			orgDetails = {
				subtitle: org?.subtitle ?? '',
				about: org?.about ?? '',
				facebook: org?.facebook ?? '',
				twitter: org?.twitter ?? '',
				instagram: org?.instagram ?? '',
			}
		}
		
		return orgDetails
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [state.current]);

	useEffect(() => {
		loadFeaturedCourses();
	}, [state.current,
		marketplaceState.activeOrganization,
		marketplaceState.category,
		marketplaceState.rightFilters,
		marketplaceState.subject,
		marketplaceState.searchKey,
		marketplaceState.group]);

	const name: string | string[] = getComputedName();
	const title: string = typeof name === "string" ? name : name.slice(0, name.length - 1).join(" ");
	const emoji: string = typeof name === "string" ? "" : name.slice(-1)[0];
	const orgDetails = getOrgDetails();

	return (
		<>
			<Skeleton loading={state.loading}>
				<CourseGroup
					items={state.items}
					title={title}
					emoji={emoji}
					orgDetails={orgDetails}
					total={state.total}
					current={state.current}
					onPaginationChange={onPaginationChange}
				/>
			</Skeleton>
		</>
	);
};

import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { message } from "antd";
import moment from "moment";
import ls from "localstorage-slim";

import AuthenticatedRoute from "App.AuthenticatedRoute";
import UnAuthenticatedRoute from "App.UnAuthenticatedRoute";
import AuthorizedGuardedRoute, { TypeOfOrganization } from "App.AuthorizedRoute";
import LandingPageRoute from "App.LandingPageRoute";
import SelectedOrganizationRoute from "App.SelectedOrganizationRoute";
import { MAIN_PATH } from "./scenes/main";
import { ADMIN_PATH } from "./scenes/admin";
import OnBoarding, { ON_BOARDING_PATH } from "scenes/onBoarding";
import SelectOrganization, { SELECT_ORGANIZATION_PATH } from "scenes/selectOrganization/SelectOrganization";
import { useStoreActions } from "store/hooks";
import SubjectService from "services/administration/subject.service";
import Login from "scenes/login";
import Dashboard from "scenes/admin";
import Main from "scenes/main";
import Certificate from "scenes/certificate/Certificate";
import Subjects, { SUBJECTS_PATH } from "scenes/subjects";
import CourseBuilderComponent, { COURSE_BUILDER_PATH } from "./scenes/courseBuilder";
import CourseComponent, { COURSE_PATH } from "./scenes/course";
import { SIGN_IN_PATH } from "scenes/login/scenes/signIn";
import { REGISTER_PATH } from "./scenes/login/scenes/register";
import MaterialInfoComponent, { MATERIAL_INFO_PATH } from "./scenes/materialInfo";
import ActivatedUserRoute from "./App.ActivatedUserRoute";
import Parent, { PARENT_PATH } from "./scenes/parent";
import { Role } from "./services/domain/login/Role";
import { Subject } from "./store/model/subjects/subject.model";
import DesignSettingService from "./services/design/designSetting.service";
import { DesignSettingDto } from "./dtos/administration/customize/DesignSetting.dto";
import { PublicSettingsResponse } from "./scenes/admin/scenes/organizations/scenes/customize/utils/types";
import { getDesignFile } from "./common/utils/Design";
import { DEFAULT_FAVICON_URL } from "./common/components/utils/WindowUtils";
import { PublicMarketplace } from "./scenes/PublicMarketplace/PublicMarketplace";


export const LANDING_PATH = "landing_page";

function App() {
	const load = useStoreActions((actions: any) => actions.subjects.load);
	const loadDesignSettings = useStoreActions((actions: any) => actions.designSettings.load);
	const setLoading = useStoreActions((actions: any) => actions.designSettings.setLoading);

	const storeAcyncClasses = (result: any) => {
		const subjects: Subject[] = result.map((item: any) => {
			const classrooms = [];
			for (let i = 0; i < item.GradeSubjects.length; i++) {
				const classroomsToAdd = item.GradeSubjects[i].Classrooms.map((c: any) => {
					return {
						id: c.id,
						name: c.name,
						File: c.File,
						Tags: c.Tags
					};
				});
				classrooms.push(...classroomsToAdd);
			}
			return {
				id: item.id,
				name: item.name,
				description: item.description,
				icon: item.icon,
				externalUrl: item.externalUrl,
				classrooms: classrooms
			};
		});
		load(subjects);
	};

	useEffect(() => {
		const fetchData = () => {
			const subjectsFromStorage = JSON.parse(localStorage.getItem("async-courses") ?? "{}");
			if (
				subjectsFromStorage &&
				(subjectsFromStorage.lastUpdatedOn === undefined ||
					moment(subjectsFromStorage.lastUpdatedOn) < moment().add(-2, "hours"))
			) {
				new SubjectService()
					.getAllWithAyncCourses()
					.then((result: any) => {
						localStorage.setItem(
							"async-courses",
							JSON.stringify({
								lastUpdatedOn: moment(),
								classes: result
							})
						);
						storeAcyncClasses(result);
						return;
					})
					.catch(error => {
						console.log(error);
						message.error("Something went wrong!");
					});
			} else if (subjectsFromStorage && subjectsFromStorage.classes) {
				storeAcyncClasses(subjectsFromStorage.classes);
			}
		};

		fetchData();
	}, [load]);

	function loadPageSettings(favIcon: DesignSettingDto | undefined, pageTitle: string | undefined) {
		const icon: HTMLAnchorElement | null = document.querySelector('[rel="icon"]');
		if (icon) {
			document.title = pageTitle ?? "Akademi.al";
			icon.href = getDesignFile(DEFAULT_FAVICON_URL, favIcon);
		}
	}

	useEffect(() => {
		setLoading(true);
		const getDesignSettings = () => {
			(async function() {
				if (ls.get("design-settings")) {
					return ls.get("design-settings") as PublicSettingsResponse;
				}

				return new DesignSettingService().findPublicSettings().then((res) => {
					ls.set("design-settings", res, {ttl: 60 * 5});
					return res;
				});
			})()
				.then((result: PublicSettingsResponse) => {
					loadPageSettings(result.options.favicon, result.general.pageTitle);
					loadDesignSettings(result.options);
					return setLoading(false);
				})
				.catch(error => {
					setLoading(false);
					console.log(error);
				});
		};

		getDesignSettings();
	}, [loadDesignSettings, setLoading]);

	return (
		<BrowserRouter>
			<Switch>
				<Route path="/" exact component={() => <Redirect to={`/${MAIN_PATH}`} />} />
				<Route path="/shiko-mplc" exact component={PublicMarketplace} />
				<Route path="/certificate" component={Certificate} />
					
				<Route path="/health" exact>
					<h3>The App is Healthy</h3>
				</Route>
				<Route path={`/${SUBJECTS_PATH}`} component={Subjects} />
				<UnAuthenticatedRoute path={`/${SIGN_IN_PATH}`} component={Login} />

				<LandingPageRoute
					path={`/${LANDING_PATH}/${SIGN_IN_PATH}`}
					exact
					component={() => <Redirect to={`/${SIGN_IN_PATH}`} />}
				/>
				<LandingPageRoute
					path={`/${LANDING_PATH}/${REGISTER_PATH}`}
					exact
					component={() => <Redirect to={`/${SIGN_IN_PATH}/${REGISTER_PATH}`} />}
				/>
				<LandingPageRoute path={`/${LANDING_PATH}/*`} component={() => <Redirect to={`/${MAIN_PATH}`} />} />
				<AuthenticatedRoute>
					<Switch>
						<AuthorizedGuardedRoute
							allowed={[
								{
									role: Role.Owner,
									type: TypeOfOrganization.Main
								},
								{
									role: Role.Admin,
									type: TypeOfOrganization.Main
								},
								{
									role: Role.Teacher,
									type: TypeOfOrganization.Main
								},
								{
									role: Role.Student,
									type: TypeOfOrganization.Main
								},
								{
									role: Role.Parent,
									type: TypeOfOrganization.Main
								}
							]}
							path={`/${ON_BOARDING_PATH}`}
							component={OnBoarding}
						/>
						<ActivatedUserRoute>
							<Switch>
								<AuthorizedGuardedRoute
									allowed={[
										{
											role: Role.Owner,
											type: TypeOfOrganization.Child
										},
										{
											role: Role.Admin,
											type: TypeOfOrganization.Child
										},
										{
											role: Role.Teacher,
											type: TypeOfOrganization.Main
										},
										{
											role: Role.Teacher,
											type: TypeOfOrganization.Child
										},
										{
											role: Role.Student,
											type: TypeOfOrganization.Main
										},
										{
											role: Role.Student,
											type: TypeOfOrganization.Child
										},
										{
											role: Role.Parent,
											type: TypeOfOrganization.Main
										}
									]}
									path={`/${SELECT_ORGANIZATION_PATH}/:role`}
									component={SelectOrganization}
								/>
								<AuthorizedGuardedRoute
									allowed={[
										{
											role: Role.Owner,
											type: TypeOfOrganization.Child
										},
										{
											role: Role.Admin,
											type: TypeOfOrganization.Child
										},
										{
											role: Role.Teacher,
											type: TypeOfOrganization.Main
										},
										{
											role: Role.Teacher,
											type: TypeOfOrganization.Child
										},
										{
											role: Role.Student,
											type: TypeOfOrganization.Main
										},
										{
											role: Role.Student,
											type: TypeOfOrganization.Child
										},
										{
											role: Role.Parent,
											type: TypeOfOrganization.Main
										}
									]}
									path={`/${SELECT_ORGANIZATION_PATH}`}
									component={SelectOrganization}
								/>
								<SelectedOrganizationRoute>
									<Switch>
										<AuthorizedGuardedRoute
											allowed={[
												{
													role: Role.Owner,
													type: TypeOfOrganization.Child
												},
												{
													role: Role.Admin,
													type: TypeOfOrganization.Child
												},
												{
													role: Role.Teacher,
													type: TypeOfOrganization.Main
												},
												{
													role: Role.Teacher,
													type: TypeOfOrganization.Child
												},
												{
													role: Role.Student,
													type: TypeOfOrganization.Main
												},
												{
													role: Role.Student,
													type: TypeOfOrganization.Child
												}
											]}
											path={`/${MAIN_PATH}`}
											component={Main}
										/>
										<AuthorizedGuardedRoute
											allowed={[
												{
													role: Role.Owner,
													type: TypeOfOrganization.Main
												},
												{
													role: Role.Admin,
													type: TypeOfOrganization.Main
												}
											]}
											path={`/${ADMIN_PATH}`}
											component={Dashboard}
										/>
										<AuthorizedGuardedRoute
											allowed={[
												{
													role: Role.Parent,
													type: TypeOfOrganization.Main
												}
											]}
											path={`/${PARENT_PATH}`}
											component={Parent}
										/>
										<AuthorizedGuardedRoute
											allowed={[
												{
													role: Role.Owner,
													type: TypeOfOrganization.Main
												},
												{
													role: Role.Owner,
													type: TypeOfOrganization.Child
												},
												{
													role: Role.Admin,
													type: TypeOfOrganization.Main
												},
												{
													role: Role.Admin,
													type: TypeOfOrganization.Child
												},
												{
													role: Role.Teacher,
													type: TypeOfOrganization.Main
												},
												{
													role: Role.Teacher,
													type: TypeOfOrganization.Child
												}
											]}
											path={`/${COURSE_BUILDER_PATH}`}
											component={CourseBuilderComponent}
										/>
										<AuthorizedGuardedRoute
											allowed={[
												{
													role: Role.Owner,
													type: TypeOfOrganization.Main
												},
												{
													role: Role.Owner,
													type: TypeOfOrganization.Child
												},
												{
													role: Role.Admin,
													type: TypeOfOrganization.Main
												},
												{
													role: Role.Admin,
													type: TypeOfOrganization.Child
												},
												{
													role: Role.Teacher,
													type: TypeOfOrganization.Main
												},
												{
													role: Role.Teacher,
													type: TypeOfOrganization.Child
												}
											]}
											path={`/${MATERIAL_INFO_PATH}`}
											component={MaterialInfoComponent}
										/>
										<AuthorizedGuardedRoute
											allowed={[
												{
													role: Role.Student,
													type: TypeOfOrganization.Main
												},
												{
													role: Role.Student,
													type: TypeOfOrganization.Child
												},
												{
													role: Role.Teacher,
													type: TypeOfOrganization.Main
												},
												{
													role: Role.Teacher,
													type: TypeOfOrganization.Child
												}
											]}
											path={`/${COURSE_PATH}`}
											component={CourseComponent}
										/>
									</Switch>
								</SelectedOrganizationRoute>
							</Switch>
						</ActivatedUserRoute>
					</Switch>
				</AuthenticatedRoute>
				<Route path="*" component={() => <Redirect to={`/${MAIN_PATH}`} />} />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
